import request from '@/utils/request'

// 获取网站配置
export function getWebSiteInfo() {
    return request({
        url: '/api/blog/webSiteInfo',
        method: 'get',
    })
}

// 首页侧边 轮播、标签墙、推荐文章
export function featchHomeData() {
    return request({
        url: '/api/blog/side',
        method: 'get',
    })
}

// 文章列表
export function fetchArticleList(params) {
    return request({
        url: '/api/blog/article',
        method: 'get',
        params: params
    })
}

// 首页文章分类tabs
export function featchCategory() {
    return request({
        url: '/api/blog/category',
        method: 'get'
    })
}

// 记录访客信息
export function report() {
    return request({
        url: '/api/blog/report',
        method: 'get',
    })
}

// 博客详细信息
export function articleInfo(id) {
    return request({
        url: '/api/blog/article/'+id,
        method: 'get'
    })
}
// 文章归档列表
export function archive() {
    return request({
        url: '/api/blog/article/archive',
        method: 'get',
    })
}

// 文章标签列表
export function fetchTagList() {
    return request({
        url: '/api/blog/tags',
        method: 'get'
    })
}
// 搜索文章
export function searchArticle(params) {
    return request({
        url: '/api/blog/article/search',
        method: 'get',
        params: params
    })
}

// 获取今日热榜
export function getHot() {
    return request({
        url: '/api/blog/todayHotList',
        method: 'get'
    })
}

// 获取邮箱验证码
export function sendEmailCode(email) {
    return request({
        url: '/captchaEmail',
        method: 'get',
        params: {
            email: email
        }
    })
}

// 账号注册
export function blogRegister(data) {
    return request({
        url: '/api/blog/register',
        method: 'post',
        data
    })
}

// 账号登录
export function blogLogin(data) {
    return request({
        url: '/api/blog/login',
        method: 'post',
        data
    })
}
// 根据token获取用户信息
export function blogGetInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}
// 账号登出
export function logout() {
    return request({
        url: '/logout',
        method: 'get'
    })
}
// 文章点赞
export function articleLike(id) {
    return request({
        url: '/api/blog/article/like',
        method: 'get',
        params: {
            articleId: id
        }
    })
}

// 添加反馈
export function addFeedback(data) {
    return request({
        url: '/api/blog/feedBack',
        method: 'post',
        data
    })
}

//查询我的反馈信息
export function getFeedback(params) {
    return request({
        url: '/api/blog/feedBack/list',
        method: 'get',
        params: params
    })
}

export function getArticleByUserId(params) {
    return request({
        url: '/v1/article/selectArticleByUserId',
        method: 'get',
        params: params
    })
}
export function deleteMyArticle(id) {
    return request({
        url: '/v1/article',
        method: 'delete',
        params: {
            id: id
        }
    })
}
export function getMyArticleInfo(id) {
    return request({
        url: '/v1/article/selectMyArticleInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function readMarkdownFile(data) {
    return request({
        url: '/v1/article/readMarkdownFile',
        method: 'post',
        data
    })
}
export function insertArticle(data) {
    return request({
        url: '/v1/article',
        method: 'post',
        data
    })
}
export function updateArticle(data) {
    return request({
        url: '/v1/article',
        method: 'put',
        data
    })
}

export function checkCode(code) {
    return request({
        url: '/v1/article/checkCode',
        method: 'get',
        params: {
            code: code
        }
    })
}





export function forgetPassword(data) {
    return request({
        url: '/oauth/forgetPassword',
        method: 'put',
        data
    })
}

export function openAuthUrl(source) {
    return request({
        url: '/oauth/render/' + source,
        method: 'get'
    })
}
export function wxIsLogin(loginCode) {
    return request({
        url: '/oauth/wechat/is_login',
        method: 'get',
        params: {
            loginCode: loginCode
        }
    })
}
export function getWechatLoginCode() {
    return request({
        url: '/oauth/wechatLoginCode',
        method: 'get',

    })
}


export function updateUserInfo(data) {
    return request({
        url: '/v1/user/',
        method: 'put',
        data
    })
}
export function getUserInfo(userId) {
    return request({
        url: '/v1/user/info',
        method: 'get',
        params: {
            userId: userId
        }
    })
}
export function selectUserInfoByToken(token) {
    return request({
        url: '/v1/user/selectUserInfoByToken',
        method: 'get',
        params: {
            token: token
        }
    })
}
export function getUserCount(id) {
    return request({
        url: '/v1/user/getUserCount',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function upload(data) {
    return request({
        url: '/file/upload',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/articles-data' },
        data
    })
}



export function getCollect() {
    return request({
        url: '/v1/collect/',
        method: 'get'
    })
}
export function collect(id) {
    return request({
        url: '/v1/collect/collect',
        method: 'get',
        params: {
            articleId: id
        }
    })
}
export function cancelCollect(id) {
    return request({
        url: '/v1/collect/',
        method: 'delete',
        params: {
            articleId: id
        }
    })
}

export function followedUser(userId) {
    return request({
        url: '/v1/followed/insertFollowed',
        method: 'post',
        params: {
            userId: userId
        }
    })
}
export function deleteFollowedUser(userId) {
    return request({
        url: '/v1/followed/deleteFollowed',
        method: 'delete',
        params: {
            userId: userId
        }
    })
}

