<template>
    <div>
        <!-- 内容 -->
        <transition name="moveCartoon" appear>
            <router-view :key="$route.fullPath" />
        </transition>
    </div>
</template>
<script>
export default {

    data() {
        return {
        }
    },

    created() {

    },
}
</script>
<style scoped>
/* 类名要对应回 name 的属性值 */
.moveCartoon-enter-active {
    animation: move .2s;
}

.moveCartoon-leave-active {
    animation: move .2s reverse;
}

@keyframes move {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translate(0);
    }
}
</style>
