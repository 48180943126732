import Vue from 'vue'
import App from './App.vue'
import store from './store'

import { vueBaberrage } from 'vue-baberrage'
Vue.use(vueBaberrage)

import jsCookie from 'js-cookie'
Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueViewer);


import element from '@/element/index'
Vue.use(element)


import Toast from '@/components/toast/index.vue';
const ToastPlugin = {
  install(Vue) {
    Vue.prototype.$toast = new Vue(Toast).$mount();
    document.body.appendChild(Vue.prototype.$toast.$el);
  },
};
Vue.use(ToastPlugin);

//下载
import download from './utils/download';
Vue.prototype.$download = download;


// highlight.js代码高亮插件
import Highlight from './utils/highlight';
Vue.use(Highlight);

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

import Clipboard from 'clipboard'
Vue.prototype.Clipboard = Clipboard


import Loading from '@/components/loading/loading';
// 注册全局组件
Vue.component('loading', Loading);

import SvgIcon from '@/components/SvgIcon'// svg component
// 注册全局组件
Vue.component('svg-icon', SvgIcon)

import ImageUpload from '@/components/ImageUpload/index'
Vue.component('image-upload', ImageUpload);

// 创建一个 Vue 实例作为事件总线
Vue.prototype.$bus = new Vue();

import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo)

import Empty from '@/components/empty/index.vue'
Vue.component("sy-empty", Empty);
import pagination from '@/components/pagination/index.vue'
Vue.component("sy-pagination", pagination);

import VueLazyLoad from 'vue-lazyload'
// 2.注册插件
Vue.use(VueLazyLoad, {
  preLoad: 1,
  // 懒加载默认加载图片
  loading: 'https://img.4min.top/blog/2024/05/05/330568032b0c4d109e726b1c6caace78.gif',
  // 加载失败后加载的图片
  error: 'https://img.4min.top/blog/2024/05/05/c589aea639954652a34c587de840a3bd.jpg',
  attempt: 1
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  // listenEvents: [ 'scroll' ]
})

/** iconfont
 * Symbol 引用
 */
import "../public/iconfont/iconfont.js";


import router from './router'

window.vm = new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant"
  });
});


