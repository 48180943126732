<template>
  <div id="app" :class="isGrey===1?'filter':''">
    <Denglong :show="isYear"/>
    <!-- 头部 -->
    <Header :user-info="userInfo"></Header>
    <!-- 侧边导航栏 -->
    <SideNavBar></SideNavBar>
    <Loading></Loading>
    <!-- <Notice></Notice> -->
    <router-view :key="$route.fullPath" style="min-height: 100vh;"/>

    <!-- 登录模态框 -->
    <LoginModle></LoginModle>

    <!-- 搜索模态框-->
    <SearchModle></SearchModle>
    <!-- 侧边栏 -->
    <Sidebar></Sidebar>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Denglong from '@/components/denglong/index.vue'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import SideNavBar from "@/components/layout/SideNavBar.vue";
import Sidebar from '@/components/layout/Sidebar.vue'
import Loading from '@/components/loading/loading.vue'
import SearchModle from '@/components/model/Search.vue'
import LoginModle from '@/components/model/Login.vue'
import Notice from '@/view/notice/topNotice.vue'
import {report, getWebSiteInfo, blogGetInfo} from '@/api'
import {getToken, setToken} from "@/utils/cookieUtil";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Sidebar,
    SideNavBar,
    SearchModle,
    LoginModle,
    Loading,
    Denglong
  },
  data() {
    return {
      isGrey: 0,
      isYear: false,
      userInfo: this.$store.state.userInfo
    }
  },
  created() {
    this.initWebSiteInfo()
    this.report()
    this.getUserInfo()
    var that = this

    //监听整个页面的 copy 事件
    document.addEventListener('copy', function (e) {
      let clipboardData = e.clipboardData || window.clipboardData;
      if (!clipboardData) return;
      let text = window.getSelection().toString();
      if (text) {
        e.preventDefault();
        clipboardData.setData('text/plain', text)
        that.$toast.success("复制成功,转载请务必保留原文链接!")
      }
    })

    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'k') {
        this.$store.state.searchDialogVisible = true
        event.preventDefault(); // 阻止默认行为
      }
    });

  },

  methods: {
    initWebSiteInfo() {
      getWebSiteInfo().then(res => {
        this.$store.commit("setWebSiteInfo", res.data)
        this.isGrey = res.data.isGrey
        this.isYear = res.data.isYear !== 0
      })
    },
    report() {
      report().then(res => {
      });
    },
    getUserInfo() {
      let flag = window.location.href.indexOf("token") !== -1
      if (flag) {
        let token = window.location.href.split("token=")[1]
        setToken(token)
      }

      // 从cookie中获取token
      let token = getToken()
      if (token != null) {
        blogGetInfo().then(res => {
          this.userInfo = res.data.user
          this.$store.commit("setUserInfo", res.data.user)
        })
      }
    }
  },

  mounted() {
    let theme = sessionStorage.getItem("theme")
    if (theme == null) {
      theme = "light"
    }
    document.documentElement.dataset.theme = theme
  },

}
</script>

<style lang="scss" scoped>
#app {

  background: var(--body-color);
  //background-image: url("https://t.alcy.cc/fj");
  //background-repeat: repeat;
  //background-size: auto 100%;
}

.filter {
  overflow: hidden;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)
}
</style>
