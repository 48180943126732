// highlight.js  代码高亮指令
import Hljs from 'highlight.js';
// 代码高亮风格，选择更多风格需导入 node_modules/hightlight.js/styles/ 目录下其它css文件
import 'highlight.js/styles/atom-one-light.css' //样式
let Highlight = {};
// 自定义插件
Highlight.install = function (Vue) {
    // 自定义指令 v-highlight
    Vue.directive('highlight', {
        // 被绑定元素插入父节点时调用
        inserted: function(el) {
            let blocks = el.querySelectorAll('pre code');
            for (let i = 0; i < blocks.length; i++) {
                console.log("blocks", blocks[i])
                // 添加这一行来移除 highlighted 标记，如果存在的话
                if (blocks[i].dataset.highlighted) {
                    delete blocks[i].dataset.highlighted;
                }
                Hljs.configure({ignoreUnescapedHTML: true});
                Hljs.highlightElement(blocks[i]);
            }
        },
        // 指令所在组件的 VNode 及其子 VNode 全部更新后调用
        componentUpdated: function(el) {
            let blocks = el.querySelectorAll('pre code');
            for (let i = 0; i < blocks.length; i++) {
                // 同样在这里也添加检查和移除标记的逻辑
                if (blocks[i].dataset.highlighted) {
                    delete blocks[i].dataset.highlighted;
                }
                Hljs.configure({ignoreUnescapedHTML: true});
                Hljs.highlightElement(blocks[i]);
            }
        }
    })
};

export default Highlight;
